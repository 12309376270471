import { createSlice } from '@reduxjs/toolkit';

const initialState = {

    initialState: {
    user: [],
    logginIn: false,
    isLoading: false,
  }};

  const userSlice = createSlice({
    name: 'user',
    initialState,

    reducers: {
    setUser: (state, action) => {
      state.user = action.payload;  // Set the user array
      state.loggedIn = true;
    },
    clearUser: (state) => {
      return initialState;
    },

    addUser: (state, action) => {
      state.user.push(action.payload);  // Add a user to the array
    },
    removeUser: (state, action) => {
      state.user = state.user.filter(user => user.id !== action.payload);  // Remove a user by ID
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;  // Set loading state
    },
   
  },
});

export const { setUser, addUser, removeUser, setLoading, clearUser } = userSlice.actions;

export default userSlice.reducer;
