import React from 'react';
import { Box, Typography, Button, Paper, Avatar, Chip, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const taskData = [
  { name: 'Martha Freese', assignedTo: 'John', color: 'teal', date: '11/10/2022', time: '5:22 PM', avatarColor: '#ff4081' },
  { name: 'Gina Vanleuven', assignedTo: 'Debbie', color: 'purple', date: '11/10/2022', time: '5:22 PM', avatarColor: '#ff4081' },
  { name: 'Pearl Torres', assignedTo: 'John', color: 'teal', date: '11/10/2022', time: '5:22 PM', avatarColor: '#ff4081' },
  { name: 'Gina Vanleuven', assignedTo: 'April', color: 'cyan', date: '11/10/2022', time: '5:22 PM', avatarColor: '#4caf50' },
  { name: 'Pearl Torres', assignedTo: 'Steve', color: 'orange', date: '11/10/2022', time: '5:22 PM', avatarColor: '#4caf50' },
];

const RequiredTasks = () => {
  return (
    <Paper sx={{ borderRadius: 4, boxShadow: 2, width: { xs: '98%', md: '44%', xl: '49%' } }}>
      <Box sx={{ padding: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Required Tasks
        </Typography>
      </Box>

      <List>
        {taskData.map((task, index) => (
          <ListItem
            key={index}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: 2,
              my: 1,
              borderRadius: 2,
              border: '1px solid #e0e0e0',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {/* Avatar */}
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: task.avatarColor }}>{task.name[0]}</Avatar>
              </ListItemAvatar>

              {/* Task Details */}
              <ListItemText
                primary={<Typography sx={{ fontWeight: 'bold' }}>{task.name}</Typography>}
                secondary={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CalendarTodayIcon sx={{ fontSize: '16px', marginRight: 1 }} />
                    <Typography variant="body2" color="textSecondary">
                      {task.date} {task.time}
                    </Typography>
                  </Box>
                }
              />
            </Box>

            {/* Assigned To */}
            <Chip label={task.assignedTo} sx={{ backgroundColor: task.color, color: '#fff', fontWeight: 'bold' }} />
          </ListItem>
        ))}
      </List>

      {/* View All Button */}
      <Box sx={{ textAlign: 'center', padding: 2 }}>
        <Button variant="contained" sx={{ borderRadius: '20px' }}>
          View All
        </Button>
      </Box>
    </Paper>
  );
};

export default RequiredTasks;
