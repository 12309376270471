import React, { useEffect } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearUser } from '../redux/slices/userSlice'; // Import your reset action

const LogoutPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const logOutUser = async () => {
      try {
        const auth = getAuth();
        await signOut(auth);
        // Dispatch clearUser to reset the Redux state
        dispatch(clearUser());

        // Clear any user-related data from localStorage
        localStorage.clear();

        // Redirect to login page
        navigate('/');
      } catch (error) {
        console.error('Sign out error', error);
      }
    };

    logOutUser(); // Call the logout function
  }, [dispatch, navigate]);

  return <div>Logging out...</div>;
};

export default LogoutPage;
