import React from 'react';
import { Avatar, Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Chip } from '@mui/material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

const participantsData = [
  { name: 'Albert Flores', email: 'tanya.hill@example.com', advertiser: 'Advertiser Name', campaign: 'Campaign Name', performance: 'Top Performance', performanceColor: 'green', avatarColor: '#ff4081' },
  { name: 'Jenny Wilson', email: 'michael.mitc@example.com', advertiser: 'Advertiser Name', campaign: 'Campaign Name', performance: 'Great Content', performanceColor: 'cyan', avatarColor: '#ff4081' },
  { name: 'Bessie Cooper', email: 'curtis.weaver@example.com', advertiser: 'Advertiser Name', campaign: 'Campaign Name', performance: 'Sales', performanceColor: 'red', avatarColor: '#ff4081' },
  { name: 'Wade Warren', email: 'debra.holt@example.com', advertiser: 'Advertiser Name', campaign: 'Campaign Name', performance: 'Top Performance', performanceColor: 'green', avatarColor: '#4caf50' },
  { name: 'Robert Fox', email: 'michael.mitc@example.com', advertiser: 'Advertiser Name', campaign: 'Campaign Name', performance: 'Great Content', performanceColor: 'cyan', avatarColor: '#ff4081' },
  { name: 'Sungla hass', email: 'sungla@example.com', advertiser: 'Advertiser Name', campaign: 'Campaign Name', performance: 'Sales', performanceColor: 'red', avatarColor: '#4caf50' },
  { name: 'Shoeie Fashio', email: 'fashio@example.com', advertiser: 'Advertiser Name', campaign: 'Campaign Name', performance: 'Top Performance', performanceColor: 'green', avatarColor: '#4caf50' },
];

const ParticipantsLeaderboard = () => {
  return (
    <Paper sx={{ borderRadius: 4, boxShadow: 2, padding: 2, width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Participants
        </Typography>
        <Button variant="outlined">View All</Button>
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Participant</strong></TableCell>
              <TableCell><strong>Advertiser</strong></TableCell>
              <TableCell><strong>Campaign</strong></TableCell>
              <TableCell><strong>Performance</strong></TableCell>
              <TableCell><strong>Icon</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {participantsData.map((participant, index) => (
              <TableRow key={index}>
                {/* Participant (Avatar + Name + Email) */}
                <TableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar sx={{ bgcolor: participant.avatarColor, marginRight: 2 }}>{participant.name[0]}</Avatar>
                    <Box>
                      <Typography sx={{ fontWeight: 'bold' }}>{participant.name}</Typography>
                      <Typography variant="body2" color="textSecondary">{participant.email}</Typography>
                    </Box>
                  </Box>
                </TableCell>

                {/* Advertiser */}
                <TableCell>{participant.advertiser}</TableCell>

                {/* Campaign */}
                <TableCell>{participant.campaign}</TableCell>

                {/* Performance */}
                <TableCell>
                  <Chip
                    label={participant.performance}
                    sx={{ backgroundColor: participant.performanceColor, color: '#fff', fontWeight: 'bold' }}
                  />
                </TableCell>

                {/* Icon */}
                <TableCell>
                  <Avatar sx={{ backgroundColor: participant.performanceColor }}>
                    <EmojiEventsIcon />
                  </Avatar>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ParticipantsLeaderboard;
