import React from 'react';
import { Card, CardContent, Typography, Grid2, Box, Button } from '@mui/material';
import { People, Campaign, Receipt, Layers } from '@mui/icons-material'; // Sample icons

const data = [
  { title: 'New Users', value: '230', icon: <People />, change: '+10.2%', description: 'this week' },
  { title: 'Campaign Applications', value: '130', icon: <Campaign />, change: '+10.2%', description: 'this week' },
  { title: 'Tickets', value: '46', icon: <Receipt />, change: '-10.2%', description: 'this week' },
  { title: 'Active Projects', value: '10.6K', icon: <Layers />, change: '+10.2%', description: 'this week' },
];

const DashboardOverview = () => {
    return (
   
          <Grid2 container spacing={3} sx={{ width: '100%'}}>
               
           
          {data.map((item, index) => (
          <Grid2 item xs={12} sm={6} md={3} key={index}>
              <Card
                sx={{
                  width: 300,    // Fixed width
                  height: 140,   // Fixed height
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: 2,
                  backgroundColor: index === 0 ? '#00bf94' : '#fff',
                  color: index === 0 ? '#fff' : '#000',
                  borderRadius: 4,
                }}
              >
                {/* Left Side - Icon */}
                <Box>

                <div>
                    <Typography variant="h6" component="div">
                      {item.value}
                    </Typography>
                    <Typography variant="subtitle1">{item.title}</Typography>
                  </div> 
 
                  <Typography
                    variant="body2"
                    sx={{
                      color: item.change.startsWith('-') ? 'red' : 'green',
                      fontWeight: 'bold',
                      mb: 1,
                    }}
                  >
                    {item.change}
                  </Typography>
                  <Typography variant="body2">{item.description}</Typography>
             
                  </Box>
               
    
                {/* Right Side - Stats */}
                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ bgcolor: '#f3f4f6', borderRadius: '50%', p: 2, mr: 2 }}>
                    {item.icon}
                  </Box>               </Box>
              </Card>
            </Grid2>
          ))}
        </Grid2> 
      );
    };

export default DashboardOverview;
