import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  CircularProgress,
  Avatar,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const campaignData = [
  {
    project: 'Android app development',
    manager: 'Rachel Green',
    dueDate: 'Jul 07, 2024',
    assignedTo: 'Mobile Team',
    status: 'Completed',
    statusColor: 'green',
    progress: 100,
  },
  {
    project: 'E-commerce platform',
    manager: 'Matte Henry',
    dueDate: 'Jul 24, 2024',
    assignedTo: 'DevOps Team',
    status: 'On Going',
    statusColor: 'orange',
    progress: 70,
  },
  {
    project: 'IOS app development',
    manager: 'Michael Brown',
    dueDate: 'Jul 25, 2024',
    assignedTo: 'Backend Team',
    status: 'Delayed',
    statusColor: 'grey',
    progress: 35,
  },
  {
    project: 'Marketing website',
    manager: 'Rachel Green',
    dueDate: 'Jul 12, 2024',
    assignedTo: 'WordPress Team',
    status: 'Completed',
    statusColor: 'green',
    progress: 100,
  },
  {
    project: 'IOS app development',
    manager: 'John Bushmill',
    dueDate: 'Jun 29, 2024',
    assignedTo: 'Backend Team',
    status: 'At Risk',
    statusColor: 'red',
    progress: 42,
  },
];

const CampaignSummaryTable = () => {
  return (
    <TableContainer component={Paper} sx={{ borderRadius: 4, boxShadow: 2 }}>
      <Typography variant="h6" sx={{ padding: 2, fontWeight: 'bold'  }}>
        Campaign Summary
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><strong>PROJECT</strong></TableCell>
            <TableCell><strong>PROJECT MANAGER</strong></TableCell>
            <TableCell><strong>DUE DATE</strong></TableCell>
            <TableCell><strong>ASSIGNED TO</strong></TableCell>
            <TableCell><strong>STATUS</strong></TableCell>
            <TableCell><strong>PROGRESS</strong></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {campaignData.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.project}</TableCell>
              <TableCell>{row.manager}</TableCell>
              <TableCell>{row.dueDate}</TableCell>
              <TableCell>{row.assignedTo}</TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <FiberManualRecordIcon
                    sx={{ color: row.statusColor, marginRight: 1, fontSize: '1rem' }}
                  />
                  <Typography>{row.status}</Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <CircularProgress
                    variant="determinate"
                    value={row.progress}
                    size={30}
                    sx={{ color: row.statusColor, marginRight: 1 }}
                  />
                  <Typography>{`${row.progress}%`}</Typography>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CampaignSummaryTable;
