import React, { useState } from 'react';
import { Box, Typography, Select, MenuItem, FormControl, InputLabel, Container } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Area, AreaChart } from 'recharts';

// Followers Data for each platform
const data = {
  Facebook: [
    { month: 'Jan', followers: 100 },
    { month: 'Feb', followers: 120 },
    { month: 'Mar', followers: 140 },
    { month: 'Apr', followers: 160 },
    { month: 'May', followers: 180 },
    { month: 'Jun', followers: 220 },
    { month: 'Jul', followers: 240 },
    { month: 'Aug', followers: 260 },
    { month: 'Sep', followers: 280 },
    { month: 'Oct', followers: 300 },
    { month: 'Nov', followers: 350 },
    { month: 'Dec', followers: 400 }
  ],
  Instagram: [
    { month: 'Jan', followers: 1500 },
    { month: 'Feb', followers: 1800 },
    { month: 'Mar', followers: 2100 },
    { month: 'Apr', followers: 3500 },
    { month: 'May', followers: 30000 },
    { month: 'Jun', followers: 75000 },
    { month: 'Jul', followers: 105132 },
    { month: 'Aug', followers: 106133 },
    { month: 'Sep', followers: 107888 },
    { month: 'Oct', followers: 108000 },
    { month: 'Nov', followers: 80000 },
    { month: 'Dec', followers: 110000 }
  ],
  X: [
    { month: 'Jan', followers: 200 },
    { month: 'Feb', followers: 220 },
    { month: 'Mar', followers: 250 },
    { month: 'Apr', followers: 270 },
    { month: 'May', followers: 300 },
    { month: 'Jun', followers: 330 },
    { month: 'Jul', followers: 360 },
    { month: 'Aug', followers: 390 },
    { month: 'Sep', followers: 410 },
    { month: 'Oct', followers: 450 },
    { month: 'Nov', followers: 480 },
    { month: 'Dec', followers: 500 }
  ]
};

const FollowerChart = () => {
  const [selectedPlatform, setSelectedPlatform] = useState('Instagram');

  // Handle platform selection
  const handleChange = (event) => {
    setSelectedPlatform(event.target.value);
  };

  return (
    <Container size='xxl' sx={{ padding: 3, borderRadius: 3, boxShadow: 1 }}>
      {/* Title and Dropdown */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Overall Followers
        </Typography>
        <FormControl>
          <InputLabel id="platform-select-label">Platform</InputLabel>
          <Select
            labelId="platform-select-label"
            id="platform-select"
            value={selectedPlatform}
            label="Platform"
            onChange={handleChange}
          >
            <MenuItem value="Facebook">Facebook</MenuItem>
            <MenuItem value="Instagram">Instagram</MenuItem>
            <MenuItem value="X">X</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Chart */}
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart data={data[selectedPlatform]} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
          <defs>
            <linearGradient id="colorFollowers" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="month" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Area type="monotone" dataKey="followers" stroke="#8884d8" fillOpacity={1} fill="url(#colorFollowers)" />
        </AreaChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default FollowerChart;
