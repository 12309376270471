import React from 'react';
import { Card, CardContent, Typography, Grid2, Box, Button, Avatar } from '@mui/material';
import { People, Campaign, Receipt, Layers } from '@mui/icons-material'; // Sample icons
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';

const data = [
  { 
    title: 'Facebook', 
    followers: '279.9K', 
    likes: '+186.0K likes/week', 
    icon: <FacebookIcon />, 
    backgroundColor: '#E0F7EF', 
    textColor: '#000', 
    followersColor: '#000', 
    changeColor: 'green' 
  },
  { 
    title: 'Instagram', 
    followers: '279.9K', 
    likes: '+144.0K likes/week', 
    icon: <InstagramIcon />, 
    backgroundColor: '#ffffff', 
    textColor: '#000', 
    followersColor: '#000', 
    changeColor: 'green' 
  },
  { 
    title: 'X', 
    followers: '223.4K', 
    likes: '+129.0K likes/week', 
    icon: <XIcon />, 
    backgroundColor: '#ffffff', 
    textColor: '#000', 
    followersColor: '#000', 
    changeColor: 'green' 
  }
];

const SocialMediaStatsCards = () => {
  return (
    <Grid2 container spacing={3} sx={{ width: '100%' }}>
      {data.map((item, index) => (
        <Grid2 item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              backgroundColor: item.backgroundColor,
              color: item.textColor,
              padding: 2,
              borderRadius: 2,
              boxShadow: 'none',
              border: '1px solid #ddd',
              minHeight: 120,
              
            }}
          >
            <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {/* Left Side - Icon and Text */}
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar sx={{ bgcolor: 'transparent', color: item.textColor, marginRight: 2 }}>
                  {item.icon}
                </Avatar>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  {item.title}
                </Typography>
              </Box>
            </CardContent>

            <CardContent sx={{ pt: 0 }}>
              {/* Followers Count */}
              <Typography variant="h4" sx={{ color: item.followersColor, fontWeight: 'bold' }}>
                {item.followers} Followers
              </Typography>

              {/* Likes Per Week */}
              <Typography variant="body2" sx={{ color: item.changeColor }}>
                {item.likes}
              </Typography>
            </CardContent>
          </Card>
        </Grid2>
      ))}
    </Grid2>
  );
};

export default SocialMediaStatsCards;