import React from 'react';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search'; // Import Search Icon

const SearchBar = () => {
  return (
    <TextField
      variant="outlined"
      placeholder="Search your page..."
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        sx: {
          borderRadius: '15px', // Rounded edges
          height: '40px',
        },
      }}
      sx={{
        width: 350, // Width of the search bar
      
        backgroundColor: '#ffffff', 
      }}
    />
  );
};

export default SearchBar;
