import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Checkbox, FormControlLabel, Avatar, Grid2, Divider, Container } from '@mui/material';
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { auth, googleProvider } from '../../config/firebase'; // Import Firebase configuration
import AppleIcon from '@mui/icons-material/Apple';
import GoogleIcon from '@mui/icons-material/Google';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser } from '../../redux/slices/userSlice';

const LoginPage = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Handle email/password login
  const handleSignIn = async (e) => {
    try {
      e.preventDefault();
      const signIn = await signInWithEmailAndPassword(auth, email, password);
      dispatch(setUser(signIn.user));

      navigate('/');
    } catch (err) {
      console.log(err);
      setError('Invalid login credentials. Please try again.');
    }
  };

  // Handle Google sign-in
  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      const signIn = await signInWithPopup(auth, googleProvider);
      dispatch(setUser(signIn.user));
      console.log(signIn.user);
      navigate('/');
    } catch (err) {
      console.log(err);
      setError('Google Sign-In failed. Please try again.');
    }
  };

  return (
    <Container align="center">
       <Grid2 container sx={{ 
      height: '100vh', 
      width: { xs: '98%', md: '75%', xl: '55%' },
      display: 'flex',
      alignItems: 'center', // Center items vertically
      justifyContent: 'center' // Center items horizontally
    }}>
      <Grid2 item align='center' xs={12} md={6} sx={{ p: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Box sx={{ mb: 5 }}>
        <Typography variant="h3" sx={{ fontWeight: 'bold', mb: 3 }}>CrowdSwell</Typography>
        
          <Typography variant="body1" sx={{ mt: 2 }}>
            Developer Version 1.1
          </Typography>
        </Box>

        {/* Social Sign-In Buttons */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
          
          <Button
            variant="outlined"
            startIcon={<GoogleIcon />}
            fullWidth
            sx={{ ml: 1, py: 1.5 }}
            onClick={handleGoogleSignIn}
          >
            Signin With Google
          </Button>
        </Box>

        <Divider sx={{ my: 2 }}>OR</Divider>

        {/* Email/Password Sign-In */}
        <Box>
          <TextField
            fullWidth
            label="Email"
            type="email"
            variant="outlined"
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            fullWidth
            label="Password"
            type="password"
            variant="outlined"
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          {error && (
            <Typography color="error" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
            <FormControlLabel control={<Checkbox />} label="Remember Me" />
            <Button href="#" color="primary">
              Forget Password?
            </Button>
          </Box>

          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 3, py: 1.5 }}
            onClick={handleSignIn}
          >
            Sign in
          </Button>
        </Box>

        <Typography variant="body2" sx={{ mt: 2 }}>
          Don’t have an account? <Button href="#">Sign Up</Button>
        </Typography>
      </Grid2>
    </Grid2></Container>
   
  );
};

export default LoginPage;
