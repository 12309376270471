import React from 'react';
import { Box, CssBaseline, Container, Typography, Button, Grid2, Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CreateCampaign = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/campaigns/create`);
      };

  return (
    
       <Button variant="contained" onClick={handleClick} sx={{borderRadius: '10px'}}>Create Campaign</Button>
       
        
  );
};

export default CreateCampaign;
