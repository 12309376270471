import React from 'react';
import { Box, CssBaseline, Container, Typography, Button, Grid2, Avatar } from '@mui/material';
import Sidebar from '../../components/navigation/MainNav';
import SearchBar from '../../components/widgets/dashboard/SearchBar';
import CampaignsOverview from '../../components/widgets/campaigns/CampaignsOverview';
import CampaignSummaryTable from '../../components/widgets/dashboard/CampaignSummaryTable';
import RecentActivities from '../../components/widgets/dashboard/RecentActivity';
import RequiredTasks from '../../components/widgets/dashboard/RequiredTasks';
import ParticipantsLeaderboard from '../../components/widgets/dashboard/ParticipantsLeaderboard';
import SettingsIcon from '@mui/icons-material/Settings';
import CardGrid from '../../components/widgets/campaigns/campaignsCards';
import SocialMediaStatsCards from '../../components/widgets/socialmedia/SocialMediaStatsCards';
import FollowerChart from '../../components/widgets/socialmedia/FollowerChart';
import CreateCampaign from '../../components/widgets/buttons/createCampaign';


const SocialMediaPage = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container maxWidth="xxl">
       <Box>
       <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 2}}>
        <Grid2 item xs>
          <SearchBar />
          </Grid2><Grid2 item>
        <Grid2 container alignItems="center" spacing={2}>
          <Grid2 item>
            <SettingsIcon />
          </Grid2>
          <Grid2 item>
            <Avatar>JG</Avatar>
          </Grid2>
        </Grid2>
      </Grid2> 
        </Grid2>
        <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 4, mt: 4}}>
       
       <Typography variant="h6" sx={{fontWeight: 'bold'}}>Overview</Typography>
       <CreateCampaign />
       
        
        </Grid2>
        <Grid2 container alignItems="center" justifyContent="space-between" sx={{ width: '100%'}}>
           <CampaignsOverview />
           </Grid2>
           <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mt: 4, width: '100%'}}>
           <SocialMediaStatsCards />
           </Grid2>
           </Box>
         
           <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mt: 4, width: '100%'}}>
           <FollowerChart />
           </Grid2>
      
        </Container>
      </Box>
    </Box>
  );
};

export default SocialMediaPage;
