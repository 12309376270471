import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
  Grid2,
  TextField,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import Sidebar from '../../components/navigation/MainNav';
import CampaignsOverview from '../../components/widgets/campaigns/CampaignsOverview';
import SettingsIcon from '@mui/icons-material/Settings';
import Avatar from '@mui/material/Avatar';
import SearchBar from '../../components/widgets/dashboard/SearchBar';

const CreateCampaign = () => {
  // State to manage form fields
  const [campaign, setCampaign] = useState({
    advertiserName: '',
    campaignName: '',
    jobType: {
      influencers: false,
      wordOfMouth: false,
      ugcAds: false,
      tvcAds: false,
    },
    remuneration: {
      influencerPayment: false,
      gifting: false,
      influencerPaymentAndGifting: false,
    },
    campaignSummary: '',
    campaignDescription: '',
    uploadCoverPhoto: null,
    uploadDocs: null,
    uploadAssets: null,
    websiteLink: '',
    startDate: '',
    endDate: '',
    contentSubmittedBy: '',
    applicationCloseDate: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCampaign({ ...campaign, [name]: value });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCampaign({ ...campaign, [name]: checked });
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container maxWidth="xxl">
          <Box>
            {/* Header Section */}
            <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
              <Grid2 item xs>
                <SearchBar />
              </Grid2>
              <Grid2 item>
                <Grid2 container alignItems="center" spacing={2}>
                  <Grid2 item>
                    <SettingsIcon />
                  </Grid2>
                  <Grid2 item>
                    <Avatar>JG</Avatar>
                  </Grid2>
                </Grid2>
              </Grid2>
            </Grid2>
          
            {/* Title and Button */}
            <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 4, mt: 4 }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Create Campaign</Typography>
             
            </Grid2>
            <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 4, mt: 4 }}>
            <CampaignsOverview />
            </Grid2>

            {/* Campaign Form */}
            <Grid2 container spacing={4}>
              {/* Advertiser & Campaign Info */}
              <Grid2 item xs={12}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Campaign Details</Typography>
                <TextField
                  fullWidth
                  label="Advertiser Name"
                  name="advertiserName"
                  value={campaign.advertiserName}
                  onChange={handleInputChange}
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label="Campaign Name"
                  name="campaignName"
                  value={campaign.campaignName}
                  onChange={handleInputChange}
                  sx={{ mb: 2 }}
                />
              </Grid2>

              {/* Job Type Section */}
              <Grid2 item xs={12}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Job Type</Typography>
                <FormControlLabel
                  control={<Checkbox name="influencers" checked={campaign.jobType.influencers} onChange={handleCheckboxChange} />}
                  label="Influencers"
                />
                <FormControlLabel
                  control={<Checkbox name="wordOfMouth" checked={campaign.jobType.wordOfMouth} onChange={handleCheckboxChange} />}
                  label="Word of Mouth"
                />
                <FormControlLabel
                  control={<Checkbox name="ugcAds" checked={campaign.jobType.ugcAds} onChange={handleCheckboxChange} />}
                  label="UGC Ads"
                />
                <FormControlLabel
                  control={<Checkbox name="tvcAds" checked={campaign.jobType.tvcAds} onChange={handleCheckboxChange} />}
                  label="TVC / CTV Ads"
                />
              </Grid2>

              {/* Renumeration Section */}
              <Grid2 item xs={12}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Renumeration</Typography>
                <FormControlLabel
                  control={<Checkbox name="influencerPayment" checked={campaign.remuneration.influencerPayment} onChange={handleCheckboxChange} />}
                  label="Influencer Payment"
                />
                <FormControlLabel
                  control={<Checkbox name="gifting" checked={campaign.remuneration.gifting} onChange={handleCheckboxChange} />}
                  label="Gifting"
                />
                <FormControlLabel
                  control={<Checkbox name="influencerPaymentAndGifting" checked={campaign.remuneration.influencerPaymentAndGifting} onChange={handleCheckboxChange} />}
                  label="Influencer Payment & Gifting"
                />
              </Grid2>

              {/* Campaign Summary */}
              <Grid2 item xs={12}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Campaign Summary</Typography>
                <TextField
                  fullWidth
                  multiline
                  maxRows={4}
                  label="Campaign Summary"
                  name="campaignSummary"
                  value={campaign.campaignSummary}
                  onChange={handleInputChange}
                  inputProps={{ maxLength: 150 }} // Character limit
                  helperText={`${campaign.campaignSummary.length}/150`}
                />
              </Grid2>

              {/* Campaign Description */}
              <Grid2 item xs={12}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Campaign Description</Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Campaign Description"
                  name="campaignDescription"
                  value={campaign.campaignDescription}
                  onChange={handleInputChange}
                />
              </Grid2>

              {/* Upload Section */}
              <Grid2 item xs={12}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Uploads</Typography>
                <Button variant="outlined" component="label">
                  Upload Cover Photo
                  <input type="file" hidden onChange={(e) => setCampaign({ ...campaign, uploadCoverPhoto: e.target.files[0] })} />
                </Button>
                <Button variant="outlined" component="label" sx={{ ml: 2 }}>
                  Upload Campaign Documentation
                  <input type="file" hidden onChange={(e) => setCampaign({ ...campaign, uploadDocs: e.target.files[0] })} />
                </Button>
                <Button variant="outlined" component="label" sx={{ ml: 2 }}>
                  Upload Campaign Assets
                  <input type="file" hidden onChange={(e) => setCampaign({ ...campaign, uploadAssets: e.target.files[0] })} />
                </Button>
                <TextField
                  fullWidth
                  label="Website / Product Info Link"
                  name="websiteLink"
                  value={campaign.websiteLink}
                  onChange={handleInputChange}
                  sx={{ mt: 2 }}
                />
              </Grid2>

              {/* Campaign Timings */}
              <Grid2 item xs={12}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Campaign Timings</Typography>
                <Grid2 container spacing={2}>
                  <Grid2 item xs={6}>
                    <TextField
                      fullWidth
                      type="date"
                      label="Start Date"
                      name="startDate"
                      value={campaign.startDate}
                      onChange={handleInputChange}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid2>
                  <Grid2 item xs={6}>
                    <TextField
                      fullWidth
                      type="date"
                      label="End Date"
                      name="endDate"
                      value={campaign.endDate}
                      onChange={handleInputChange}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid2>
                  <Grid2 item xs={6}>
                    <TextField
                      fullWidth
                      type="date"
                      label="Content Submitted by"
                      name="contentSubmittedBy"
                      value={campaign.contentSubmittedBy}
                      onChange={handleInputChange}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid2>
                  <Grid2 item xs={6}>
                    <TextField
                      fullWidth
                      type="date"
                      label="Application Close Date"
                      name="applicationCloseDate"
                      value={campaign.applicationCloseDate}
                      onChange={handleInputChange}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid2>
                </Grid2>
              </Grid2>
            </Grid2>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default CreateCampaign;
