import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css';
import PrivateRoute from './auth/privateRoute';
import Dashboard from './pages/Dashboard';
import VideoPlayer from './pages/VideoPlayer';
import LoginPage from '../src/auth/login';
import CampaignsPage from './pages/campaigns';
import SocialMediaPage from './pages/SocialMedia';
import TasksPage from './pages/tasks';
import CreateCampaign from './pages/campaigns/create';
import LogoutPage from './auth/logout';



function App() {
  return (
    <Router>
      <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/logout" element={<LogoutPage />} />
        <Route path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route path="/campaigns" element={<PrivateRoute><CampaignsPage /></PrivateRoute>} />
        <Route path="/campaigns/create" element={<PrivateRoute><CreateCampaign /></PrivateRoute>} />
        <Route path="/social-media" element={<PrivateRoute><SocialMediaPage /></PrivateRoute>} />
        <Route path="/tasks" element={<PrivateRoute><TasksPage /></PrivateRoute>} />
        <Route path="/videoplayer" element={<PrivateRoute><VideoPlayer /></PrivateRoute>} />
     </Routes>
    </Router>
  );
}

export default App;
