import React, { useState } from 'react';
import { Box, Grid, Button, Card, CardContent, CardMedia, Typography, Chip } from '@mui/material';

// Sample Card Data
const cardsData = [
  {
    id: 1,
    image: 'https://source.unsplash.com/random/300x200?sig=1',
    advertiser: 'Advertiser Name',
    campaign: 'Campaign Name',
    description: 'Campaign Summary Information (limit character count)',
    status: 'LIVE',
    statusColor: 'green',
    tag: 'Influencer',
    tagColor: 'lightblue',
  },
  {
    id: 2,
    image: 'https://source.unsplash.com/random/300x200?sig=2',
    advertiser: 'Advertiser Name',
    campaign: 'Campaign Name',
    description: 'Campaign Summary Information (limit character count)',
    status: 'DRAFT',
    statusColor: 'orange',
    tag: 'WOM',
    tagColor: 'pink',
  },
  {
    id: 3,
    image: 'https://source.unsplash.com/random/300x200?sig=3',
    advertiser: 'Advertiser Name',
    campaign: 'Campaign Name',
    description: 'Campaign Summary Information (limit character count)',
    status: 'APPLICATIONS OPEN',
    statusColor: 'blue',
    tag: 'UGC',
    tagColor: 'purple',
  }
];

const CardComponent = ({ card }) => (
  <Card sx={{ maxWidth: 345 }}>
    <CardMedia
      component="img"
      height="200"
      image={card.image}
      alt={card.campaign}
    />
    <CardContent>
      {/* Tag */}
      <Chip
        label={card.tag}
        sx={{ backgroundColor: card.tagColor, color: '#fff', fontWeight: 'bold', mb: 1 }}
      />
      <Typography variant="body2" color="textSecondary">
        {card.advertiser}
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
        {card.campaign}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {card.description}
      </Typography>
      {/* Status */}
      <Typography variant="h6" sx={{ color: card.statusColor, mt: 1 }}>
        {card.status}
      </Typography>
    </CardContent>
  </Card>
);

const CardGrid = () => {
  const [visibleCards, setVisibleCards] = useState(9);

  // Handle "View More" button click
  const handleViewMore = () => {
    setVisibleCards((prev) => prev + 9); // Load 9 more cards on each click
  };

  // Create an array of visible cards
  const displayedCards = [];
  for (let i = 0; i < visibleCards; i++) {
    displayedCards.push(cardsData[i % 3]); // Repeat the three unique cards
  }

  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Grid container spacing={3}>
        {displayedCards.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <CardComponent card={card} />
          </Grid>
        ))}
      </Grid>

      {/* "View More" Button */}
      <Box sx={{ textAlign: 'center', mt: 3 }}>
        <Button variant="contained" onClick={handleViewMore}>
          View More
        </Button>
      </Box>
    </Box>
  );
};

export default CardGrid;
