import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './useAuth'; // Your custom hook to get auth state
import LinearProgress from '@mui/material/LinearProgress';
import { Box } from '@mui/material';
const PrivateRoute = ({ children }) => {
  const { currentUser, loading } = useAuth();

  if (loading) {
    return (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      );}

  return currentUser ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
