import React, { useState } from 'react';
import { Box, Tab, Tabs, Typography, Button, Paper } from '@mui/material';

const activityData = [
  { name: 'Influencer name', action: 'Content went live' },
  { name: 'Influencer name', action: 'Submitted content for approval' },
  { name: 'Influencer name', action: 'Changed their fee' },
  { name: 'Advertiser Name', action: 'Approved an influencer' },
  { name: 'Influencer name', action: 'Content went live' },
  { name: 'Advertiser Name', action: 'Published a campaign' },
];

function TabPanel({ children, value, index }) {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const RecentActivities = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Paper sx={{ borderRadius: 4, boxShadow: 2, width: { xs: '98%', md: '44%', xl: '49%' } }}>
      <Box sx={{ padding: 2}}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Recent Activities
        </Typography>
      </Box>

      {/* Tabs */}
      <Tabs value={tabValue} onChange={handleTabChange} centered>
        <Tab label="Activity" />
        <Tab label="Update" />
      </Tabs>

      {/* Activity Panel */}
      <TabPanel value={tabValue} index={0}>
        {activityData.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: 2,
              my: 1,
              borderRadius: 2,
              border: '1px solid #e0e0e0',
            }}
          >
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                {item.name}
              </Typography>
              <Typography variant="body2">{item.action}</Typography>
            </Box>
            <Button
              variant="contained"
              sx={{ backgroundColor: '#00c4cc', color: '#fff', borderRadius: '20px' }}
            >
              View
            </Button>
          </Box>
        ))}
      </TabPanel>

      {/* Update Panel */}
      <TabPanel value={tabValue} index={1}>
        <Typography>No updates available.</Typography>
      </TabPanel>
    </Paper>
  );
};

export default RecentActivities;
